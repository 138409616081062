.xHJDEa_app {
  flex-direction: column;
  width: 100%;
  height: 100%;
  display: flex;
}

.xHJDEa_app .xHJDEa_inner, .xHJDEa_app .xHJDEa_inner2 {
  flex-direction: row;
  flex-grow: 1;
  display: flex;
}

.xHJDEa_app .xHJDEa_inner3 {
  flex-direction: column;
  flex-grow: 1;
  display: flex;
}

.xHJDEa_app .xHJDEa_inner3 > * {
  flex-grow: 1;
}

.xHJDEa_terminal {
  background-color: #000;
}

._1x7Xoa_file-picker {
  background-color: var(--color-file-picker);
  --filepicker-gap: .5rem;
  --filepicker-padding: .5rem;
  --rotcont-gap: var(--filepicker-gap);
  --rotcont-height: calc(1em + 2 * var(--filepicker-padding));
  width: 250px;
}

._1x7Xoa_file-picker > ul {
  padding: var(--filepicker-gap);
  width: 100%;
}

._1x7Xoa_file-picker > ul > li {
  cursor: pointer;
  padding: var(--filepicker-padding);
  background-color: #fff0;
  border-radius: 3px;
  justify-content: start;
  align-items: start;
  width: 100%;
  transition: background-color .1s;
  display: flex;
}

._1x7Xoa_file-picker > ul > li:hover {
  background-color: #ffffff1a;
}

.bwFsja_code-editor {
  flex-grow: 1;
  position: relative;
}

.bwFsja_code-editor .bwFsja_code-container {
  position: absolute;
  inset: 0;
  overflow: auto scroll;
}

.bwFsja_code-editor .bwFsja_code-container > * {
  --rotcont-gap: 0rem;
  --rotcont-height: calc(1em);
  font-family: var(--font-family-monospace);
  min-height: 100%;
  padding: .5rem;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.bwFsja_code-editor .bwFsja_the-code {
  color: #f000;
  caret-color: #fff;
}

.bwFsja_code-editor .bwFsja_the-code:focus {
  outline: none;
}

.bwFsja_code-editor .bwFsja_the-code span {
  background-color: #0000 !important;
}

.bwFsja_code-editor .bwFsja_the-visual-code {
  -webkit-user-select: none;
  user-select: none;
}

.bwFsja_code-editor .bwFsja_line {
  font-family: inherit;
  display: block;
}

.jtKauG_tool-bar {
  background-color: var(--color-tool-bar);
  height: 2em;
  padding-left: .5rem;
  padding-right: .5rem;
  display: inline-block;
}

.jtKauG_tool-bar > ul {
  flex-direction: row;
  justify-content: start;
  align-items: stretch;
  height: 100%;
  display: flex;
}

.jtKauG_tool-bar > ul > li {
  cursor: pointer;
  background-color: #fff0;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: .3rem .5rem;
  transition: background-color .1s;
  display: flex;
}

.jtKauG_tool-bar > ul > li:hover {
  background-color: #ffffff1a;
}

.jtKauG_tool-bar > ul > li span.jtKauG_letter {
  transform: rotate(var(--angle)) translateX(calc(cos(var(--angle)) * 3px));
  width: calc(1ch + sin(var(--angle)) * 1ch);
  display: inline-block;
}

.angle-inverted .jtKauG_tool-bar .jtKauG_letter {
  width: calc(1ch - sin(var(--angle)) * 1ch);
}

.FTDAHq_container {
  background: #00000080;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  inset: 0;
}

.FTDAHq_thing {
  background-color: var(--color-background);
  border-radius: 20px;
  width: min(800px, 100vw);
  height: min(500px, 100vh);
  padding: 20px;
}

.LcwkIq_container {
  cursor: pointer;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  overflow: hidden;
}

.LcwkIq_container > * {
  width: 100%;
  height: 100%;
  display: inline-block;
  position: absolute;
  inset: 0;
}

.LcwkIq_back {
  object-fit: cover;
  filter: blur(10px);
  width: calc(100% + 20px);
  height: calc(100% + 20px);
  inset: -10px;
}

.LcwkIq_front {
  object-fit: contain;
  transform: rotate(var(--angle)) scale(.75);
}

.LcwkIq_loading {
  justify-content: center;
  align-items: center;
  display: flex;
}
/*# sourceMappingURL=index.81536b99.css.map */
